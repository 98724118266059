import React from 'react';

import Home from '../../../assets/images/home.png';
import Email from '../../../assets/images/email.png';
import Phone from '../../../assets/images/phone.png';

import './maps.scss';

const Maps = () => (
  <>
    <section id="maps" className="container">
      <div className="maps-wrapper">
        <div className="text-maps">
          <div className="item">
            <div className="img-wrapper">
              <img src={Home} />
            </div>
            <p>Praça Tristão da Cunha, 56 - Mooca, São Paulo</p>
          </div>
          <div className="item">
            <div className="img-wrapper">
              <img src={Email} />
            </div>
            <p>sedafio@sedafio.com.br</p>
          </div>
          <div className="item">
            <div className="img-wrapper">
              <img src={Phone} />
            </div>
            <p>+55 11 988307278</p>
          </div>
        </div>
        <div className="map">
          <div className="mapouter">
            <div className="gmap_canvas">
              <iframe
                width="550"
                height="400"
                id="gmap_canvas"
                src="https://maps.google.com/maps?q=praca%20tristao%20da%20cunha%2056&t=&z=13&ie=UTF8&iwloc=&output=embed"
                frameborder="0"
                scrolling="no"
                marginheight="0"
                marginwidth="0"
              ></iframe>
            </div>
          </div>
        </div>
      </div>
    </section>
  </>
);

export default Maps;
