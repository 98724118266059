import React, { useState } from 'react';
import axios from 'axios';

import './form.scss';

import LoadingGif from '../../../assets/images/loading.gif';

function Form() {
  const [resposta, setResposta] = useState('off');
  const [loading, setLonding] = useState('');
  const [campos, setCampos] = useState({
    nome: '',
    email: '',
    telefone: '',
    mensagem: ''
  });
  function handleInputChange(event) {
    if (event.target.name === 'anexo')
      campos[event.target.name] = event.target.files[0];
    else campos[event.target.name] = event.target.value;
    setCampos(campos);
  }
  function send({ reset }) {
    setLonding('true');
    const formData = new FormData();
    Object.keys(campos).forEach(key => formData.append(key, campos[key]));
    axios
      // .post('http://localhost:5000/send', formData, {
      .post('https://sedafio-mailer.herokuapp.com/send', formData, {
        headers: {
          'Content-Type': `multipart/form-data; boundary=${formData._boundary}`
        }
      })
      .then(response => {
        console.log(response.data);
        setResposta('on');
        setLonding('false');
        reset();
      })
      .catch(error => {
        console.log(error);
        setLonding('false');
      });
  }

  function handleFormSubmit(event) {
    event.preventDefault();
    console.log(campos);
    send(campos);
  }

  return (
    <>
      <section id="form" className="container">
        <div className="form-wrapper">
          <div className="title-form">
            <h1>Entre em contato conosco!</h1>
            <p>
              Tire suas dúvidas sobre qualquer tipo de serviço,
              <br /> ficaremos felizes em ajudar e começar um novo projeto com
              você!
            </p>
          </div>
          <div className="formulario-wrapper">
            {resposta == 'off' ? (
              <form onSubmit={handleFormSubmit}>
                <input
                  type="text"
                  name="nome"
                  placeholder="Nome:"
                  onChange={handleInputChange}
                />
                <input
                  type="email"
                  name="email"
                  placeholder="E-mail:"
                  onChange={handleInputChange}
                />
                <input
                  type="tel"
                  name="telefone"
                  placeholder="Telefone:"
                  onChange={handleInputChange}
                />
                <textarea
                  name="mensagem"
                  id="mensagem"
                  placeholder="Mensagem:"
                  onChange={handleInputChange}
                ></textarea>
                <div className="buttons-form">
                  {loading == '' ? (
                    <input type="submit" value="Enviar" id="enviar" />
                  ) : null}
                  {loading == 'true' ? <img src={LoadingGif} /> : null}
                </div>
              </form>
            ) : (
              <p className="resposta">
                Obrigado pela mensagem! Em breve, entraremos em contato.
              </p>
            )}
          </div>
        </div>
      </section>
    </>
  );
}

export default Form;
