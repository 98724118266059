import React from 'react';

import { SEO } from '../../components/seo';

import Header from '../../components/header/header';
import Footer from '../../components/footer/footer';

import Form from './form';
import Maps from './maps';

import '../../reset.css';

const Contato = () => (
  <>
    <SEO title="Contato" />
    <Header title="Contato" />

    <Form />
    <Maps />

    <Footer />
  </>
);

export default Contato;
